<template>
  <DataTable
    :loading="loading"
    :headers="headers"
    :can-search="true"
    v-bind="properties"
    class="tw--m-5 tw-shadow-none"
    @filter="loadContactRentals"
    @changePage="loadContactRentals"
  >
    <template #item-reference="{ item }">
      <EntityHoverable :value="item" />
    </template>
  </DataTable>
</template>

<script>
import DataTable from '@/components/DataTable'
import EntityHoverable from '@/components/iam/EntityHoverable'

import { getContactRentals } from '@/services/contacts'
import { errorModal } from '@/modalMessages'
import { mapState } from 'vuex'

export default {
  name: 'ContactRentals',
  components: {
    DataTable,
    EntityHoverable
  },
  data () {
    return {
      loading: false,
      // The object properties includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      properties: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    ...mapState('contacts', ['contact']),

    headers () {
      return [
        { value: 'reference', text: 'Referentie' },
        { value: 'address', text: 'Adres' },
        { value: 'city.name', text: 'Stad' }
      ]
    }
  },
  created () {
    this.loadContactRentals()
  },
  methods: {
    async loadContactRentals (payload) {
      try {
        this.loading = true
        const response = await getContactRentals({ contactId: this.contact.id, ...payload })
        this.properties = response.data
        this.loading = false
        return response
      } catch {
        errorModal('Er is iets misgegaan bij het inladen van de huurpanden.')
      }
    }
  }
}
</script>
